function dataURLtoFile(dataURL, fileName) {
  const dataURLParts = dataURL.split(",");
  const mimeType = dataURLParts[0].match(/:(.*?);/)[1];
  const base64Data = atob(dataURLParts[1]);

  let dataLength = base64Data.length;
  const byteArray = new Uint8Array(dataLength);
  while (dataLength--) {
    byteArray[dataLength] = base64Data.charCodeAt(dataLength);
  }

  const blob = new Blob([byteArray], { type: mimeType });

  return new File([blob], fileName, { type: mimeType });
}

export default { dataURLtoFile };
