<template>
  <div class="image-cropper">
    <img ref="image" :src="src" />
  </div>
</template>

<script>
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: function () {
        return {
          aspectRatio: 1,
          scalable: false,
          zoomable: false,
        };
      },
    },
  },
  watch: {
    src(newVal) {
      if (newVal) {
        this.cropper.replace(newVal);
      }
    },
  },
  data: () => ({
    cropper: null,
    destination: null,
  }),
  mounted() {
    this.initCropper();
  },
  methods: {
    initCropper() {
      const image = this.$refs.image;
      this.cropper = new Cropper(image, {
        ...this.options,
        cropend: () => {
          this.crop();
        },
        ready: () => {
          this.crop();
        },
      });
    },
    crop() {
      const canvas = this.cropper.getCroppedCanvas();

      this.destination = canvas.toDataURL();

      this.$emit("crop", this.destination);
    },
  },
};
</script>

<style lang="scss" scoped></style>
