<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save()"
    >
      <template #content>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              label="Título"
              v-model="form.title"
              class="required"
              filled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-file-input
              v-model="coverFile"
              v-if="!form.cover || cropDialog"
              @change="onFileChange"
              @click:clear="clearImage"
              filled
              hide-details
              label="Imagem de capa"
              prepend-icon="mdi-camera"
              class="required"
              max-width="500px"
            ></v-file-input>

            <div v-if="form.cover && !cropDialog" class="pa-8">
              <p>
                Imagem de capa:

                <v-btn
                  icon
                  color="red"
                  @click="clearImage"
                  aria-label="Remover imagem de capa"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </p>

              <img :src="coverPreviewSrc" max-width="500px" />
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              v-model="form.summary"
              label="Resumo"
              hide-details
              filled
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              class="required"
              v-model="form.NewsCategoryId"
              :items="newsCategoryOptions"
              :loading="loadingCategories"
              label="Categoria"
              placeholder="Selecione a categoria da notícia"
              filled
              hide-details
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              class="required"
              :loading="loadingCities"
              :items="cities"
              v-model="form.Cities"
              label="Cidades"
              multiple
              clearable
              filled
              hide-details
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <vue-editor
              :editorOptions="editorOptions"
              v-model="form.text"
            ></vue-editor>
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>

    <v-dialog v-model="cropDialog" hide-overlay persistent width="650">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Cortar imagem</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <ImageCropper
                v-if="cropDialog && coverDataURL"
                :src="coverDataURL"
                @crop="onCropImage"
                :options="{
                  aspectRatio: 16 / 9,
                }"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="clearImage">
            <v-icon> mdi-close </v-icon> Cancelar
          </v-btn>
          <v-btn color="primary" small @click="cropDialog = false">
            <v-icon> mdi-check </v-icon> Aplicar corte
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import fileHelper from "@/helpers/file";
import { VueEditor } from "vue2-editor";
import ImageCropper from "@/components/ImageCropper.vue";
import { cloudUrl } from "@/config/config.json";

export default {
  components: {
    BaseForm,
    VueEditor,
    ImageCropper,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    loadingCities: false,
    loadingCategories: false,
    cropDialog: false,
    form: {
      text: "<span>Texto da notícia...</span>",
      cover: null,
    },
    actionAfterOperation: null,
    cities: [],
    newsCategoryOptions: [],
    editorOptions: {
      modules: {
        toolbar: [
          [{ size: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "super" }, { script: "sub" }],
          [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ direction: "rtl" }, { align: [] }],
          ["link", "video", "formula"],
          ["clean"],
        ],
      },
    },
    coverFile: null,
    coverDataURL: null,
  }),
  computed: {
    coverPreviewSrc() {
      if (!this.coverDataURL && this.form.cover) {
        return `${cloudUrl}/${this.form.cover}`;
      }

      return this.form.cover;
    },
  },
  methods: {
    validate() {
      const errors = [];

      if (!this.form.title) errors.push("O campo TÍTULO é obrigatório");
      if (!this.form.cover && this.mod === "insert")
        errors.push("O campo IMAGEM DE CAPA é obrigatório");
      if (this.form.text == "<p>Descreva o texto da notícia...</p>")
        errors.push("Escreva o TEXTO da notícia");
      if (!this.form.Cities.length) errors.push("Informe ao menos uma CIDADE");
      if (!this.form.NewsCategoryId)
        errors.push("Informe ao menos uma CATEGORIA");

      return errors;
    },
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        const formData = this.getFormData();

        await this.$axios.post(`/news`, formData);

        this.clearForm();
        this.clearImage();

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        const formData = this.getFormData();

        await this.$axios.put(`/news/${this.$route.params.id}`, formData);

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    getFormData() {
      const { cover, ...data } = this.form;

      const formData = new FormData();

      for (const key in data) {
        if (Array.isArray(data[key])) {
          data[key].forEach((value) => {
            formData.append(key, value);
          });
        } else {
          formData.append(key, data[key]);
        }
      }

      if (cover && this.coverFile)
        formData.append(
          "cover",
          fileHelper.dataURLtoFile(cover, this.coverFile.name)
        );

      return formData;
    },
    resizeImage(dataURL) {
      return new Promise((resolve, reject) => {
        const maxWidth = 500;
        const img = new Image();
        img.src = dataURL;

        img.onload = function () {
          if (img.width > maxWidth) {
            const ratio = maxWidth / img.width;
            const newHeight = img.height * ratio;

            const canvas = document.createElement("canvas");
            canvas.width = maxWidth;
            canvas.height = newHeight;

            const canvasRenderingContect = canvas.getContext("2d");
            canvasRenderingContect.drawImage(img, 0, 0, maxWidth, newHeight);

            const resizedDataURL = canvas.toDataURL();

            resolve(resizedDataURL);
          } else {
            resolve(dataURL);
          }
        };

        img.onerror = function () {
          reject(new Error("Erro ao carregar a imagem."));
        };
      });
    },
    clearImage() {
      this.form.cover = null;
      this.coverFile = null;
      this.coverDataURL = null;
      this.cropDialog = false;
    },
    async loadCities() {
      try {
        this.loadingCities = true;

        let url = `/cities`;

        const response = await this.$axios.get(url);

        this.setCities(response.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loadingCities = false;
      }
    },
    setCities(data) {
      const parsedData = [...data];

      this.cities = parsedData.map((c) => ({
        text: `${c.name} - ${c.uf}`,
        value: c.id,
      }));

      if (this.mod === "insert") this.form.Cities = parsedData.map((c) => c.id);
    },
    async getNewsCategories() {
      try {
        this.loadingCategories = true;

        const res = await this.$axios.get("/news-categories");

        this.setCategoriesData(res.data);

        this.loadingCategories = false;
      } catch (error) {
        this.loadingCategories = false;
        this.handleErrors(error);
      }
    },
    setCategoriesData(categories) {
      this.newsCategoryOptions = categories.map((c) => {
        return {
          value: c.id,
          text: c.name,
        };
      });
    },
    async loadNewsData() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(
          `/news/${this.$route.params.id}`
        );

        this.setNewsData(response.data);
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },
    setNewsData(data) {
      const cpData = { ...data };

      this.form = cpData;

      this.form.Cities = cpData.Cities.map((item) => item.id);
    },
    async onCropImage(cropedImage) {
      this.form.cover = await this.resizeImage(cropedImage);
    },
    onFileChange() {
      if (this.coverFile) {
        this.coverDataURL = URL.createObjectURL(this.coverFile);

        this.cropDialog = true;
      }
    },
    afterOperation() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
    },
    clearForm() {
      this.form = {
        text: "<span>Texto da notícia...</span>",
        cover: null,
      };
    },
  },
  created() {
    this.loadCities();
    this.getNewsCategories();

    if (this.mod === "update") this.loadNewsData();
  },
};
</script>

<style></style>
